<template>
  <div class="login-body">
    <h3 class="text-center">{{$t('ACCOUNT.LOGIN')}}</h3>
    <form class="login-form" @submit.prevent="onSubmit">
      <!-- No Permissions Alert -->
      <div v-if="showNoPermissions" class="alert alert-danger">
        <p>{{$t('ACCOUNT.NO_ACCESS_INFO1')}}</p>
        <p>{{$t('ACCOUNT.NO_ACCESS_INFO2')}}</p>
      </div>

      <!-- Warning Alert -->
      <div v-if="showWarning" class="alert alert-danger" role="alert">
        {{$t('ACCOUNT.INVALID_EMAIL_PASSWORD')}}
        <button type="button" class="close" @click="showWarning = false">&times;</button>
      </div>

      <!-- Email Input -->
      <input
        type="email"
        @keyup.enter="onSubmit"
        v-model="form.email"
        class="form-input"
        :class="{ 'is-invalid': $v.form.email.$error }"
        :placeholder="$t('MEMBER.EMAIL')"
      />

      <!-- Password Input -->
      <input
        type="password"
        @keyup.enter="onSubmit"
        v-model="form.password"
        class="form-input"
        :class="{ 'is-invalid': $v.form.password.$error }"
        :placeholder="$t('MEMBER.PASSWORD')"
      />

      <!-- Submit Button -->
      <div class="button-container">
        <button class="btn-login" type="submit">{{$t('ACCOUNT.LOGIN')}}</button>
      </div>

      <!-- Forgot Password Link -->
      <div class="link-container">
        <router-link to="/ml-forget" class="line-link blue-link">{{$t('MEMBER.FORGET_PASSWORD')}}</router-link>
      </div>
    </form>
  </div>
</template>

<style scoped>
.login-body {
  max-width: 500px;
  padding: 0 1rem;
}

h3.text-center {
  text-align: center;
  margin: 2rem 0;
}

.login-form {
  padding: 2rem 0;
  display: flex;
  flex-direction: column;
}

.form-input {
  margin-bottom: 1rem;
  padding: 0.75rem;
  font-size: 1rem;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
}

.is-invalid {
  border-color: #dc3545;
}

.alert {
  padding: 1rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem;
}

.alert-danger {
  color: #721c24;
  background-color: #f8d7da;
  border-color: #f5c6cb;
}

.alert .close {
  float: right;
  font-size: 1.2rem;
  font-weight: bold;
  line-height: 1;
  color: #000;
  opacity: 0.5;
  background: none;
  border: none;
  cursor: pointer;
}

.button-container {
  display: flex;
  justify-content: center;
}

.btn-login {
  font-size: 1.2rem;
  font-weight: 500;
  padding: 1rem 3rem;
  background-color: #5d78ff;
  border: none;
  color: #fff;
  cursor: pointer;
}

.btn-login:hover {
  background-color: #3758ff;
}

.link-container {
  margin-top: 1rem;
  text-align: center;
}

.line-link {
  text-decoration: none;
}

.blue-link {
  color: #5d78ff;
}

@media screen and (max-width: 600px) {
  .login-body {
    padding: 0 1rem;
  }

  .btn-login {
    width: 100%;
  }
}
</style>

<script>
import { setKeyValue, getKeyValue } from '@/core/services/simpleStore';
import { mapState } from 'vuex';
import { LOGIN } from '@/core/services/store/auth.module';
import { SET_COMPANY_ID } from '@/core/services/store/common.module';
import axios from 'axios';
import { validationMixin } from 'vuelidate';
import { email, minLength, required } from 'vuelidate/lib/validators';
import { clearCurrentCompanyId } from '@/core/services/companyAccess';

export default {
  mixins: [validationMixin],
  name: 'login',
  data() {
    return {
      form: {
        email: '',
        password: ''
      },
      showWarning: false,
      showNoPermissions: false
    };
  },
  validations: {
    form: {
      email: {
        required,
        email
      },
      password: {
        required,
        minLength: minLength(3)
      }
    }
  },
  mounted() {
    this.form.email = getKeyValue('user_login_email');
    clearCurrentCompanyId();
  },
  methods: {
    async onSubmit() {
      this.$v.form.$touch();
      if (this.$v.form.$anyError) {
        this.showWarning = true;
        return;
      }

      const email = this.$v.form.email.$model;
      const password = this.$v.form.password.$model;
      const loader = this.$loading.show();

      try {
        const res = await axios.post('/user/login', { email, password });
        setKeyValue('user_login_email', email);

        if (res.status === 200) {
          if (res.data.selected_company_id) {
            this.$store.dispatch(SET_COMPANY_ID, res.data.selected_company_id);
          }

          this.$store.dispatch(LOGIN, {
            user: {
              user_id: res.data.id,
              email: email
            },
            token: res.data.token,
            refresh_token: res.data.refresh_token.refresh_token,
            expires_ts: res.data.refresh_token.expires_ts
          });

          this.$router.push({ name: 'ml-auth-forward' });
        } else if (res.status === 403) {
          this.showNoPermissions = true;
          this.showWarning = false;
        } else if (res.status === 406) {
          this.showWarning = true;
          this.showNoPermissions = false;
        }
      } catch (err) {
        console.error(err);
        this.showWarning = true;
      } finally {
        loader.hide();
      }
    }
  },
  computed: {
    ...mapState({
      errors: state => state.auth.errors
    })
  }
};
</script>
